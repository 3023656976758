@import '../../styles/variables';

.iframeContainer {
    width: 100%;
    height: 100vh;
}

iframe {
    width: 100%;
    height: 100vh;
    .error-container {
        display: none;
    }    

}

.error-page {
    .error-container {
        display: none;
    }
}

.quicksight-cards {
  background: #FFFFFF;
  box-shadow: 0px 0px 12px rgb(0 0 0 / 10%);
  border-radius: 16px;
  height: 250px;
}
