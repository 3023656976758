@import '../../styles/variables';

.react-tabs {
    -webkit-tap-highlight-color: transparent;
  
    &__tab-list {
      margin: 0 !important;
      padding: 0;
    }
  
    &__tab {
      display: inline-block;
      border: 2px solid transparent;
      border-bottom: none;
      bottom: -1px;
      position: relative;
      list-style: none;
      padding: 6px 12px;
      cursor: pointer;
      transition: 0.1s;
  
      &--selected {
        background: #fff;
        border-color: #e2e6e9;
        font-weight: 600;
        color:  #000;
        border-radius: 5px 5px 0 0;
        box-shadow: 0 -4px 10px -8px rgba(115, 115, 115, 0.75)
      }
  
      &--disabled {
        color: GrayText;
        cursor: default;
      }
  
      &:focus {
        outline: none;
      }
    }

    &__tab:hover{
      border: 2px solid #e2e6e9;
      border-radius: 5px 5px 0 0;
      box-shadow: 0 -4px 10px -8px rgba(115, 115, 115, 0.75);
      border-bottom: transparent !important;
    }
  
    &__tab-panel {
      display: none;
      border: 1px solid #e2e6e9;
      padding: 5%;
      &--selected {
        display: block;
      }
    }
  }
  